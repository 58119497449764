import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuardService} from "./auth-guard.service";

const routes: Routes = [
    {
        path: 'news',
        loadChildren: () => import('./news/news.module').then(m => m.NewsModule),
        canActivate: [AuthGuardService],
        canActivateChild: [AuthGuardService]
    },
    {
        path: 'companies',
        loadChildren: () => import('./companies/companies.module').then(m => m.CompaniesModule),
        canActivate: [AuthGuardService],
        canActivateChild: [AuthGuardService]
    },
    {
        path: 'statistics',
        loadChildren: () => import('./statistics/statistics.module').then(m => m.StatisticsModule),
        canActivate: [AuthGuardService],
        canActivateChild: [AuthGuardService]
    },
    {
        path: 'streams',
        loadChildren: () => import('./streams/streams.module').then(m => m.StreamsModule),
        canActivate: [AuthGuardService],
        canActivateChild: [AuthGuardService]
    },
    {
        path: 'streams-direct',
        loadChildren: () => import('./streams-direct/streams-direct.module').then(m => m.StreamsDirectModule),
        canActivate: [AuthGuardService],
        canActivateChild: [AuthGuardService]
    },
    {
        path: 'streams-hold',
        loadChildren: () => import('./streams-hold/streams-hold.module').then(m => m.StreamsHoldModule),
        canActivate: [AuthGuardService],
        canActivateChild: [AuthGuardService]
    },
    {
        path: 'pb',
        loadChildren: () => import('./web-postback/web-postback.module').then(m => m.WebPostbackModule),
        canActivate: [AuthGuardService],
        canActivateChild: [AuthGuardService]
    },
    {
        path: 'wp',
        loadChildren: () => import('./white-pages/white-pages.module').then(m => m.WhitePagesModule),
        canActivate: [AuthGuardService],
        canActivateChild: [AuthGuardService]
    },
    {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    },
    {path: '', redirectTo: 'news', pathMatch: 'full'},
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
