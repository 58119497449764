import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

export interface TMainMenu {
    url?: string | any[];
    children?: TMainMenu[]
    name: string;
    index: number;
    key: string
    exact?: boolean
    iconClass?: string
    access?: {(obj:any):boolean}
}

@Injectable()
export class MenuService {
    private readonly subject: Subject<TMainMenu[]>;
    private value: TMainMenu[] = [];

    constructor() {
        this.subject = new BehaviorSubject<TMainMenu[]>([])
    }

    getMenu(): Subject<TMainMenu[]> {
        return this.subject
    }

    addMenuItem(item: TMainMenu[] | TMainMenu): void {
        if (Array.isArray(item)) {
            this.value.push(...item);
        } else {
            this.value.push(item);
        }
        this.value.sort((a, b) => a.index - b.index);
        this.subject.next(this.value)
    }

    clear(next: boolean = false) {
        this.value = []
        if (next)
            this.subject.next(this.value)
    }
}
