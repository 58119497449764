import {Component, ElementRef, HostListener, Input, Renderer2} from '@angular/core';
import {ModalClass} from "./modal.class";


@Component({
    selector: 'ui-modal',
    template: `<div (click)="click($event)" [ngStyle]="style" [class]="modal?.position">
        <button *ngIf="modal?.position === 'center'" class="close" (click)="modal.hide()"></button>
        <ng-content></ng-content>
        <div class="corner"></div>
    </div>`,
    styleUrls: ['./modal.component.css'],
})
export class UIModalComponent {
    private _modal!: ModalClass
    get modal(): ModalClass {
        return this._modal
    }
    @Input() set modal(val: ModalClass) {
        this._modal = val

        this.renderer.setAttribute(this.host.nativeElement, 'position', val.position)

        if (this.modal.element?.getBoundingClientRect) {

            const scrollX = window.scrollX
            const scrollY = window.scrollY
            const el = this.modal.element.getBoundingClientRect()
            // console.log(el)
            const host = this.host.nativeElement.firstChild.getBoundingClientRect()
            switch (this.modal.position) {
                case "bottom":
                    // console.log(el, host)
                    this.style = {
                        position: 'absolute',
                        'top.px': el.bottom + 20,
                        'left.px': el.left
                    }
                    break
                case "top":
                    this.style = {
                        position: 'absolute',
                        'top.px': scrollY + el.bottom - host.height - 20,
                        'left.px': scrollX + el.left
                    }
                    break
                case "right":
                    this.style = {
                        position: 'absolute',
                        'top.px': el.top,
                        'left.px': el.left + el.width
                    }
                    break
                case "left":
                    // this.style = {
                    //     position: 'absolute',
                    //     'top.px': scrollY + el.top - 20,
                    //     'left.px': scrollX + el.left - host.width - 20
                    // }
                    setTimeout(()=>{
                        const host = this.host.nativeElement.firstChild.getBoundingClientRect()

                        this.style = {
                            position: 'absolute',
                            'top.px': scrollY + el.top - 20,
                            'left.px': scrollX + el.left - host.width - 20
                        }
                    })
                    break
                case "atop-row":
                    const parent = this.modal.element.parentElement.parentElement.parentElement.getBoundingClientRect()
                    // console.log(parent)
                    this.style = {
                        position: 'absolute',
                        'top.px': scrollY + parent.top,
                        'height.px': parent.height,
                        'left.px': scrollX + parent.left,
                        'width.px': parent.width,
                        padding: 0,
                        outline: '1px solid red',
                        height: '44px'
                    }
                    break
            }
        }

    }
    style: any;

    constructor(private host: ElementRef, private renderer: Renderer2) {
    }

    firstClick = true
    @HostListener('document:click', ['$event'])
    onClick(e: any) {
        if (String(e.target.className).includes('mce')) return;

        if (!e.my_modal && !this.firstClick)
            this.modal.hide()

        this.firstClick = false
    }

    click(e: any) {
        e.my_modal = true
    }

}
