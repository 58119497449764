import {Injectable} from '@angular/core';
import {ConfigService} from "../_services/config.service";
import {Router} from "@angular/router";
import {ErrorService} from "../_services/error.service";
import {LoaderService} from "../_services/loader.service";
import {FacadeServices} from "../_services/facade.services";
import {UITableHeader} from "../_share/table/table";
import { environment } from '../../environments/environment';

interface TErrorResult {
    ru?: string;
    en?: string;
}

export interface TOperationResult<T> extends TErrorResult {
    count?: number;
    result: boolean
    version?: number
    data?: T[]
    block_id?: string
}

export interface TGetParam {
    search: any, page: number
}

export interface TService<T> {
    urlGet: string
    urlSet: string
    urlDel: string
    urlArchive: string
    title: string

    tableColumns: UITableHeader[]
    displayedColumns: string[]
    mainUrl: string

    cfg: ConfigService;
    error: ErrorService;

    get(obj: TGetParam, pageLimit?:number): Promise<TOperationResult<T>>
    getByID(id: number): Promise<TOperationResult<T>>
    remove(id: number): Promise<TOperationResult<T>>
    save(data: T, id: string | number): Promise<TOperationResult<T>>
    back(): void

    getMainData(): Promise<TOperationResult<T>>

    archive:{(id: number, status?: number | null, deleted?: boolean | null): Promise<TOperationResult<T>>}

    getAll(obj: TGetParam): Promise<TOperationResult<T>>
}


@Injectable()
export class BaseService<T> implements TService<T> {
    public urlGet = '';
    public urlSet = '';
    public urlDel = '';
    public urlArchive = '';

    public title: string = ''
    public displayedColumns: string[] = [];
    public tableColumns: UITableHeader[] = [];

    public mainUrl = ''

    constructor(private services: FacadeServices) {}

    public get cfg(): ConfigService {
        return this.services.cfg
    }
    public get error(): ErrorService {
        return this.services.error
    }

    get router(): Router {
        return this.services.router
    }

    private get loader(): LoaderService {
        return this.services.loader
    }

    private _init: RequestInit = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        mode: 'cors',
    }

    init(data: any): RequestInit {
        let result: any = {...this._init}
        let auth: any;

        const fake = JSON.parse(sessionStorage.getItem('fake') || 'false')
        if (fake)
            auth = JSON.parse(sessionStorage.getItem(this.cfg.authName) || '');// фейковый токен храним в сессии
        else
            auth = JSON.parse(localStorage.getItem(this.cfg.authName) || '');
        // console.log(fake, auth)

        result.headers.Authorization = auth.token
        if (data) result.body = JSON.stringify(data)
        // console.log(result)
        return result
    }

    async get({page, search}: TGetParam, pageLimit?:number): Promise<TOperationResult<T>> {
        return await this.tryCatch(this.urlGet, async () => {
            // console.log(page)
            const limit = pageLimit || this.cfg.pageLimit
            const skip = page * limit - limit
            // console.log(skip, limit)
            const response = await fetch(this.cfg.get('apiUrl') + this.urlGet, this.init({skip, limit, ...search}))
            if (response.ok) {
                const result = await response.json()
                return result
            }
        }, {result: true, data: []})
    }

    async remove(id: number): Promise<TOperationResult<T>> {
        return await this.tryCatch(this.urlDel && id, async () => {
            const response = await fetch(this.cfg.get('apiUrl') + this.urlDel + id, this.init(null))
            if (response.ok) {
                const result = await response.json()
                return result
            }
        })
    }

    async getByID(id: number): Promise<TOperationResult<T>> {
        return await this.tryCatch(this.urlGet && id, async () => {
            const url = this.urlGet + (id ? id : '');
            const response = await fetch(this.cfg.get('apiUrl') + url, this.init({skip:0, limit:1}))
            if (response.ok) {
                const result = await response.json()
                return result
            }
        })
    }

    async save(data: T, id: number): Promise<TOperationResult<T>> {
        return await this.tryCatch(this.urlSet, async () => {
            const url = this.urlSet + (id ? id : '');
            const response = await fetch(this.cfg.get('apiUrl') + url, this.init(data))
            if (response.ok) {
                const result = await response.json()
                return result
            }
        })
    }

    back() {
        this.router.navigate([this.mainUrl]);
    }

    async getMainData(): Promise<TOperationResult<T>> {
        return await this.tryCatch(true, async () => {
            const response = await fetch(this.cfg.get('apiUrl') +  '/web/main', this.init(undefined))
            if (response.ok) {
                const result = await response.json()
                return result
            }
        })
    }

    async archive(id: number): Promise<TOperationResult<T>> {
        return await this.tryCatch(this.urlArchive && id, async () => {
            const response = await fetch(this.cfg.get('apiUrl') + this.urlArchive + id, this.init(null))
            if (response.ok) {
                const result = await response.json()
                return result
            }
        })
    }

    async tryCatch(condition: any, tryFunction: {(): Promise<TOperationResult<T>>}, defaultResult: TOperationResult<T> = {result: false}) {
        this.loader.show()
        if (condition) {
            try {
                await this.services.cfg.load()

                const res = await tryFunction()

                // console.log(environment.version, res.version)
                if (res.version && environment.version < res.version) {
                    this.loader.needReload.next(true);
                }

                this.loader.hide()
                return res
            } catch (e) {
                this.error.handleError(e)
            }
        }
        this.loader.hide()
        return defaultResult
    }


    async getAll({page = 1, search}: TGetParam): Promise<TOperationResult<T>> {
        return await this.tryCatch(this.urlGet, async () => {
            // console.log(page)
            const limit = 1000
            const skip = page * limit - limit
            // console.log(skip, limit)
            const response = await fetch(this.cfg.get('apiUrl') + this.urlGet, this.init({skip, limit, ...search}))
            if (response.ok) {
                const result = await response.json()
                // TODO: исправить ошибку, по которой самое последнее добавленное в архив или вынутое из архива промо выдает баг
                if (result.data && (skip + result.data.length) < result.count) {
                    const add = await this.getAll({page: page+1, search})
                    return result.data.concat(add.data)
                } else
                    return result
            }
        }, {result: true, data: []})
    }
}
