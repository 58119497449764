import {Injectable} from "@angular/core";
import {BaseService} from "./_base/base-service";
import {FacadeServices} from "./_services/facade.services";
import {TMainMenu} from "./menu.service";

@Injectable()
export class AppMainMenuService extends BaseService<TMainMenu> {
    constructor(services: FacadeServices) {
        super(services);
    }

    getMenu(): TMainMenu[] {
        return [
            {key: 'can_stats', url: ['/statistics'], exact: true, name: 'Статистика', iconClass: 'statistics', index: 1},
            {key: 'can_news', url: ['/news'], name: 'Новости', iconClass: 'news', index: 4},
            {key: 'can_streams', url: ['/streams'], name: 'Потоки', iconClass: 'streams', index: 5, access: obj => !obj.d},
            {key: 'can_streams', name: 'Потоки', iconClass: 'streams', index: 5, children: [
                    {key: 'can_streams', url: ['/streams'], name: 'Новостные', iconClass: 'streams', index: 1},
                    {key: 'can_streams', url: ['/streams-direct'], name: 'Товарные', iconClass: 'streams', index: 2},
                    {key: 'can_streams', url: ['/streams-hold'], name: 'DM', iconClass: 'streams', index: 3},
                    {key: 'can_streams', url: ['/wp'], name: 'WhitePages', iconClass: 'servers', index: 4},
                ], access: obj => obj.d},

            {key: 'can_stats', url: ['/pb/edit'], name: 'Постбек', iconClass: 'settings', index: 10},
            // {key: 'can_companies', url: ['/companies'], name: 'Компании', iconClass: 'companies', index: 11, access: obj => !obj.teamlead},
            {key: 'can_companies', url: ['/companies'], name: 'Компании', iconClass: 'companies', index: 11, access: obj => obj.teamlead},
        ]
    }
}
