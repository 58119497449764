import {ChangeDetectorRef, Component} from '@angular/core';
import {ModalClass} from "./_share_home/modal/modal.class";
import {debounceTime} from "rxjs/operators";
import {FacadeServices} from "./_services/facade.services";

declare var location:any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent {
    constructor(
        private fs: FacadeServices,
        private CDRef: ChangeDetectorRef
    ) {
    }

    modalError = new ModalClass('center')
    loaderShow = false
    needReload = false

    ngOnInit() {
        this.fs.error.subject
            .subscribe((errorStr: string) => {
                this.modalError.hide()
                this.modalError.show(null, errorStr)
            });

        this.fs.loader.subject
            // .pipe(
            //     debounceTime(100)
            // )
            .subscribe((show: boolean) => {
                this.loaderShow = show
                this.CDRef.detectChanges()
            });
        this.fs.loader.needReload
            .subscribe((needReload:boolean)=>{
                // console.log(needReload)
                this.needReload = needReload
            })
    }

    windowReload() {
        location.reload();
    }
}
