import {Injectable} from '@angular/core';
import {CanActivate, CanActivateChild, Router} from '@angular/router';
import {ConfigService} from "./_services/config.service";

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanActivateChild {
    constructor(public cfg: ConfigService, public router: Router) {
    }

    canActivate(): boolean {
        let auth: any;
        try {
            const fake = JSON.parse(sessionStorage.getItem('fake') || 'false')
            if (fake)
                auth = JSON.parse(sessionStorage.getItem(this.cfg.authName) || '');// фейковый токен храним в сессии
            else
                auth = JSON.parse(localStorage.getItem(this.cfg.authName) || '');
        } catch (e) {
            auth = {}
        }

        if (auth && !auth.token) {
            this.router.navigateByUrl('/auth/login');
            return false;
        }
        return true;
    }

    canActivateChild(): boolean {
        return this.canActivate();
    }
}
