import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    public subject: Subject<boolean> = new Subject<boolean>()
    private _showLoaders = 0
    private _hideLoaders = 0

    public needReload:  Subject<boolean> = new Subject<boolean>()

    show() {
        this._showLoaders++
        this.subject.next(this.state())
    }

    hide() {
        if (this.state()) {
            this._hideLoaders++
            this.subject.next(this.state())
        }
    }

    state() {
        return this._showLoaders > this._hideLoaders
    }
}
