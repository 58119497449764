import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from "@angular/router";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {AuthGuardService} from "./auth-guard.service";
import {AppMainMenuService} from "./app-main-menu.service";
import {ErrorComponent} from "./error.component";
import {FacadeServices} from "./_services/facade.services";
import {ShareHomeModule} from "./_share_home/share-home.module";
import {MenuService} from "./menu.service";
import {ScriptService} from "./_services/script.service";
import {FormTextareaService} from "./_share/form/textarea/form-textarea.service";

@NgModule({
    declarations: [AppComponent, ErrorComponent],
    imports: [
        RouterModule,
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ShareHomeModule
    ],
    providers: [
        FacadeServices,
        MenuService,
        AuthGuardService,
        AppMainMenuService,
        ScriptService,
        FormTextareaService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
