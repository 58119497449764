import {Injectable} from '@angular/core';
import {ErrorService} from "./error.service";

export interface Config {
    apiUrl: string;
    DEBUG: boolean;
    authName: string;
    timeout: number;
    retry: number;
    pageLimit: number
}

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    private configUrl = 'assets/config.json';
    private config!: Config;

    constructor(private error: ErrorService) {}

    get Debug() {
        return this.get('DEBUG')
    }

    get apiUrl() {
        return this.get('apiUrl')
    }

    get authName() {
        return this.get('authName')
    }

    get(key: string) {
        const config: { [key: string]: any } = this.config
        return config && config[key];
    }

    get pageLimit() {
        return this.get('pageLimit')
    }

    async load() {
        if (!this.config) {
            try {
                const response = await fetch(this.configUrl) // todo добавить повтор запроса в случае неудачи
                if (response.ok) {
                    this.config = await response.json()
                    return true
                }
            } catch (e) {
                this.error.handleError(e)
                return false
            }
        }
        return true
    }
}


