import {Injectable} from "@angular/core";

declare var document: any;

@Injectable()
export class ScriptService {

    private scripts = new Set<string>();

    constructor() {
    }

    load(scripts: string[]) {
        var promises: any[] = [];
        scripts.forEach((script) => promises.push(this.loadScript(script)));
        return Promise.all(promises);
    }

    loadScript(src: string) {
        return new Promise((resolve, reject) => {
            //resolve if already loaded
            if (this.scripts.has(src)) {
                resolve('Already loaded');
            }
            else {
                //load script
                let script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = src;
                if (script.readyState) {  //IE
                    script.onreadystatechange = () => {
                        if (script.readyState === "loaded" || script.readyState === "complete") {
                            script.onreadystatechange = null;
                            this.scripts.add(src);
                            resolve('Loaded');
                        }
                    };
                } else {  //Others
                    script.onload = () => {
                        this.scripts.add(src);
                        resolve('Loaded');
                    };
                }
                script.onerror = (error: any) => reject(error);
                document.getElementsByTagName('head')[0].appendChild(script);
            }
        });
    }
}
