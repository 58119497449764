import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Subject, throwError, TimeoutError} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  public subject: Subject<string>
  constructor() {
    this.subject = new Subject<string>()
  }

  public handleError(error: any) {
    console.log(error)
    if (error.error instanceof HttpErrorResponse) {
      this.subject.next('Ошибка сети:' + error.message);
    } else if (error.error instanceof TimeoutError) {
      this.subject.next('Ошибка сети:' + error.message);
    } else if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      // console.error('An error occurred:', error.message);
      this.subject.next('Ошибка сети:' + error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // console.error(
      //   `Backend returned code ${error.status}, ` +
      //   `body was: ${error.message}`);
      this.subject.next('Ошибка сервера:' + error.message);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

}
