import {Injectable} from "@angular/core";
import {BaseService} from "../../../_base/base-service";
import {FacadeServices} from "../../../_services/facade.services";

export interface FAQ {
    id?: number
    question: string
    answer?: string
    language?: string
    delete?: boolean
}

@Injectable()
export class FormTextareaService extends BaseService<any> {
    constructor(services: FacadeServices) {
        super(services);
    }

    images_upload_handler(blobInfo: any, success: any, failure: any) {
        this.tryCatch(blobInfo, async () => {
            const response = await fetch(this.cfg.get('apiUrl') + '/admin/image', this.init({
                route: 'faq',
                image: "data:" + blobInfo.blob().type + ";base64," + blobInfo.base64()
            }))
            if (response.ok) {
                const result = await response.json()
                return result.data
            }
        })
            .then(res => success(res))
            .catch(e => {
                failure('Произошла ошибка при загрузке картинки')
            })
    }
}
