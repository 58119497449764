import {Injectable} from '@angular/core';
import {ConfigService} from "./config.service";
import {Router} from "@angular/router";
import {ErrorService} from "./error.service";
import {LoaderService} from "./loader.service";

@Injectable({
    providedIn: 'root'
})
export class FacadeServices {
    constructor(
        public cfg: ConfigService,
        public router: Router,
        public error: ErrorService,
        public loader: LoaderService
    ) {
        cfg.load()
    }
}
